import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';

// 複数ファイル共通のタイプ管理
import { CHAT } from './types';

interface ChatPaperProps {
    chat: CHAT; // コメントオブジェクト
    isMobile: boolean;
}

const ChatPaper: React.FC<ChatPaperProps> = ({ chat, isMobile }) => {
    const theme = useTheme();

    // 画面サイズを受けての文字サイズ決定（Mobileの時は文字サイズを小さくする）
    const fontSize = isMobile ? 'body2' : 'body1';

    return (
        <Grid container justifyContent={chat.role==="user" ? "flex-end":"flex-start"} spacing={0}>
        {/* <Grid item> */}
        {/* <Grid item xs={12}> */}
            <Paper variant="elevation" elevation={0} style={{
                padding: 24,
                paddingTop: 12,
                paddingBottom: 12,
                borderRadius: chat.role==="user" ?  '10px 10px 0px 10px':'10px 10px 10px 0px',
                color: chat.role==="user" ? theme.palette.grey[50]:theme.palette.grey[800],
                backgroundColor: chat.role==="user" ? theme.palette.primary.main:theme.palette.grey[100],
                // backgroundColor: blue[50],
                // border: "1px solid aliceblue",
                display: 'inline-block',  // 内部テキストに合わせたサイズに調整
                maxWidth: '80%',
            }}>
                <Grid container justifyContent="flex-end" spacing={0}>
                <Grid item color={chat.role==="user" ? theme.palette.grey[50]:theme.palette.grey[800]}>
                    <Typography variant={fontSize} sx={{ whiteSpace: 'pre-line' }}>
                        {chat.content}
                    </Typography>
                </Grid>
                {/* good/badの評価 */}
                {/* <Grid item container justifyContent="flex-end" xs={12}>
                    <IconButton size="small"><ThumbUpAltIcon fontSize="small"/></IconButton>
                    <IconButton size="small"><ThumbDownAltIcon fontSize="small"/></IconButton>
                </Grid> */}
                </Grid>
            </Paper>
            {/* </Grid> */}
        </Grid>
    );
};

export default ChatPaper;
