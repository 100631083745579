import React from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import ChatPaper from './components/ChatPaper';

import { AppBar, Toolbar } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import InputBase from '@mui/material/InputBase';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';  // 画面サイズ判定用

import SendIcon from '@mui/icons-material/Send';
import PetsIcon from '@mui/icons-material/Pets';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

// 複数ファイル共通のタイプ管理
import { CHAT } from './components/types';

type RESULT = {
  chatId: string,
  input: string,
  result: string,
}

// ChatId生成関数
function generateId(): string {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const length = 16;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  // 画面サイズがスマホサイズかどうか

	// api url
  const url = "https://o9kmjjw96l.execute-api.ap-northeast-1.amazonaws.com/develop/"

  // ChatId
  const [chatId, setChatId] = React.useState("");
  React.useEffect(() => {
    setChatId(generateId());  // 画面が呼ばれたときにIDを生成する
  }, []);

  const [text, setText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  // const [path, setPath] = React.useState('chat_one');  // develop
  const [path, setPath] = React.useState('chat');  // prod
  const [isOpening, setIsOpening] = React.useState(false);
  const [messages, setMessages] = React.useState<CHAT[]>([]);

  // 免責事項
  const firstChat: CHAT = {
    role: "assistant",
    content: "こんにちは、私はAIアシスタントCatbotです。保護猫の里親を増やしたいという目的で作られました。\
    保護猫の情報や飼う上での不安、疑問があればお気軽にお聞きください。\n\n\
    ただし、私がお答えできるのは一般的なアドバイスです。あなた自身で判断し、自己責任で行動してください。"
  }
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const contractText = "1. サービスの提供に関して\n\
  ・当サービス提供者（以後、提供者）は、サービスの中断や停止、利用不能、データの消失や破損、不正アクセス等が発生した場合でも、一切の責任を負いません。\n\
  ・提供者は、利用者がサービスを利用したことにより生じた一切の損害（間接的な損害、結果的損害、懲罰的損害を含む）についても、一切の責任を負いません。\n\
  ・提供者は、サービス利用に関連して発生するトラブルについて、一切の責任を負いません。\n\n\
  2. コンテンツに関して\n\
  ・提供者は、掲載される情報・コンテンツの正確性・信頼性について、いかなる保証もいたしません。\n\
  ・提供者は、サービスに掲載された情報・コンテンツの内容によって生じた損害について、一切の責任を負いません。\n\n\
  3. 利用者の責任\n\
  ・利用者は、自己の責任においてサービスを利用し、その利用により生じた損害については、自己の責任において解決するものとします。\n\
  ・利用者がサービスを利用して第三者に損害を与えた場合、利用者自身がその責任を負うものとします。\n\n\
  4. 免責事項の範囲\n\
  ・提供者が免責事項を適用できない場合でも、提供者が負う損害賠償額は、利用者が当該サービスに対して実際に支払った金額に限定されるものとします。\n\n\
  5. 利用データに関して\n\
  ・利用者が当サービスを利用することにより提供者が取得する情報（チャット入力情報等）については、データの分析やサービス改善等の目的で利用することがあります。\n\
  ・利用者は、提供者が利用者のデータを上記の目的で活用することに同意するものとします。\n\
  ・また、上記の理由から個人情報等の重要情報は入力しないでください。個人情報の入力により発生する損害やトラブルについては、提供者は一切の責任を負いません。"

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpening(false);
  };

  // 画面自動スクロール用
  const scrollBottomRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    scrollBottomRef?.current?.scrollIntoView();
  }, [messages]);

	// const GetData = () => {
  //   axios.get(url).then((res: AxiosResponse<HELLO>) => {
  //     const { data, status } = res;
  //     setData(data);
	// 	});
	// };

  const PostData = (input: string) => {
    if (!input) return;
    setIsLoading(true);  // Loading開始
    const currentMessages = messages  // 現在のmessagesの退避
    setText("")  // textのリセット
    // 入力したチャットの反映
    const newChat: CHAT = {role: "user", content: input}
    setMessages((messages) => ([...messages, newChat]))
    // APIリクエスト
    axios.post(url+path, {"chatId": chatId, "input": input, "messages": currentMessages}).then((res: AxiosResponse<RESULT>) => {
      const { data, status } = res;
      // レスポンスの表示
      const newChatRes: CHAT = {role: "assistant", content: data.result}
      setMessages((messages) => ([...messages, newChatRes]))
		})
    .catch(error => {
      setText(input)  // 入力内容の巻き戻し
      setMessages(currentMessages)  // Chat状況の巻き戻し
      alert("作成失敗：時間をあけてからの再実行をお願いします。")
    })
    .finally(() => {
      setIsLoading(false);  // Loading終了
    })
  }

  return (
    <Stack bgcolor={theme.palette.grey[50]}>
      <AppBar position="sticky" color='default' elevation={0} sx={{ borderBottom: 1, borderColor: 'grey.300', borderWidth: '2px' }}>
        <Container maxWidth="md" disableGutters={true}>
          <Toolbar variant="dense" sx={{ justifyContent: 'center', minHeight: '64px' }}>
            <PetsIcon color='primary' fontSize="large" sx={{ mr: 1, marginBottom: '2px' }}/>
            <Typography variant="h4" component="a"
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
              }}
            >
              Catbot
            </Typography>
            <div style={{ flexGrow: 1 }}></div>
          </Toolbar>
        </Container>
      </AppBar>
      <Snackbar open={isOpening} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" color="info" elevation={1} sx={{ width: '100%' }}>
          Copied!
        </Alert>
      </Snackbar>
      <Container maxWidth="md">
        <Stack direction="column" justifyContent="center" spacing={3}>
          <Grid container justifyContent="center" spacing={0}>
            {/* 免責事項 */}
            <Grid item padding={1}>
              <Stack direction="row" alignItems="center">
                <Button variant="text" onClick={() => handleOpenDialog()}>免責事項</Button>
                <Typography variant="body2" color={theme.palette.grey[800]}>に同意の上でご利用ください</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" justifyContent="center" spacing={3}>
                {/* 最初の挨拶 */}
                <ChatPaper chat={firstChat} isMobile={isMobile}/>
                {/* チャット本体 */}
                {messages.map(chat =>
                  <ChatPaper chat={chat} isMobile={isMobile}/>
                )}
                {/* 画面自動スクロール用 */}
                <div ref={scrollBottomRef}/>
              </Stack>
              <Box height={isMobile ? 64:96} />
            </Grid>
          </Grid>
        </Stack>
      </Container>
      {/* <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0 }}> */}
      <AppBar position="fixed" color="transparent" elevation={0} sx={{ top: 'auto', bottom: 0 }}>
        <Container maxWidth="md" disableGutters={true}>
          <Toolbar variant="dense" sx={{ justifyContent: 'center', minHeight: isMobile?"80px":"128px" }}>
            <Paper
              component="form"
              onSubmit={(e) => {
                e.preventDefault();  // Enterキーを押した時のページがリロードされる動きをキャンセル
                PostData(text);      // Enterキーを押したときにtextが送信される様にする
              }}
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100vw"}}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 , fontSize: isMobile?theme.typography.body2:theme.typography.body1}}
                placeholder="例：保護猫を飼えるか心配です"
                value={text}
                onChange={(event) => setText(event.target.value)}
              />
              <IconButton color="primary" size={isMobile ? "medium":"large"} onClick={() => PostData(text)}>
                {isLoading ?
                  <CircularProgress size={24} color="inherit" /> :
                  <SendIcon fontSize="inherit" sx={{ transform: "rotate(320deg)", marginTop: "-4px", marginBottom: "4px" }}/>
                }
              </IconButton>
            </Paper>
          </Toolbar>
        </Container>
      </AppBar>
      {/* 免責事項 */}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>免責事項</DialogTitle>
        <DialogContentText padding={3} paddingTop={0}>
          <Typography sx={{ whiteSpace: 'pre-line' }}>
            {contractText}
          </Typography>
        </DialogContentText>
      </Dialog>
    </Stack>
  );
}

export default App;
